/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  box-sizing: border-box;
}

.ant-table {
  overflow-x: auto;
}
