
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

 //@primary-color: #de0c0c;



// --------------- Default styles for some components  ---------------
.ant-drawer-body {
  padding: 0 !important;
}
// --------------- END  ---------------
